import { useEffect, useRef } from 'react';
import styles from './InfoBlock.module.css';

export default function InfoBlock() {
  const itemsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'translateY(0)';
          }
        });
      },
      { threshold: 0.1 }
    );

    itemsRef.current.forEach(el => observer.observe(el));
    
    return () => observer.disconnect();
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Почему это работает?</h2>
      
        <div className={styles.grid}>
            <div 
                ref={el => itemsRef.current[0] = el}
                className={styles.card}
            >
                <div className={styles.icon}>🤖</div>
                <h3>Полная автоматизация</h3>
                <p>
                Сервис работает 24/7, анализируя новые вакансии 
                и отправляя отклики по вашим критериям
                </p>
            </div>

            <div 
                ref={el => itemsRef.current[1] = el}
                className={styles.card}
            >
                <div className={styles.icon}>🆓</div>
                <h3>Бесплатно навсегда</h3>
                <p>
                Я, как автор проекта, рассчитываю на прибыль от рекламы, а не на ограбление людей
                </p>
            </div>

            <div 
                ref={el => itemsRef.current[2] = el}
                className={styles.card}
            >
                <div className={styles.icon}>🔒</div>
                <h3>Безопасность</h3>
                <p>
                Используется официальное API HH.ru, 
                ваши данные не хранятся на серверах find my job
                </p>
            </div>
        </div>
        <div className={styles.ctaWrapper}>
            <a href="https://t.me/finder_my_work_bot" className={styles.ctaButton}>
            Начать бесплатно
            </a>
        </div>
    </div>
    
  );
}