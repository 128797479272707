import { useState, useEffect } from 'react';
import styles from './CoolInfo.module.css';


const rotatingMessages = [
  '- Полностью бесплатно - ',
  '- Автоматические отклики - ',
  '- Фильтры по вакансиям - ',
  '- Рассылка сопроводительного -',
  '- До 200 откликов в день - '
];

export default function CoolInfo() {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex(prev => (prev + 1) % rotatingMessages.length);
    }, 2300);
    return () => clearInterval(timer);
  }, []);


  return (

      <div className={styles.mainHeader}>
        <h1 className={styles.mainTitle}>
          <span className={styles.staticText}>Find My Job это</span>
          <span className={styles.rotatingTextWrapper}>
            {rotatingMessages.map((msg, index) => (
              <span 
                key={index}
                className={`${styles.rotatingText} ${
                  index === activeIndex ? styles.active : ''
                }`}
              >
                {msg}
              </span>
            ))}
          </span>
        </h1>
        <h2 className={styles.defaultText}>Единственный бесплатный сервис автооткликов на hh.ru</h2>
        <h3 className={styles.defaultText}>Работает через телеграм бот. Откликайтесь до 200 раз в день</h3>
      <div className={styles.ctaWrapper}>
                  <a href="https://t.me/finder_my_work_bot" className={styles.ctaButton}>
                  Подключить автоотклики
                  </a>
              </div>
      </div>
      )}