
import styles from './Header.module.css';

export default function Header() {
  return (
    <header className={styles.header}>
      <nav className={`${styles.nav} container`}>
        <div className={styles.logo}>  FIND MY JOB </div>
        <ul className={styles.menu}>
          {/* <li><a href="#courses">Как это работает</a></li> */}
          <li><a href="https://t.me/zcasu">Автор проекта</a></li>
        </ul>
      </nav>
    </header>
  );
}