import { useState, useEffect } from 'react';
import styles from './Hero.module.css';

// Импортируем изображения (пути нужно заменить на реальные)
import authImage from './assets/auth.png';
import botMenu from './assets/bot_menu.png';
import hhStat from './assets/hh_stat.png';
import phone from './assets/phone.png';

const featuresData = [
  { text: '1. Авторизуетесь в hh.ru через нашего бота', image: authImage },
  { text: '2. Используйте удобное меню бота', image: botMenu },
  { text: '3. Начните откликаться', image: hhStat },
  { text: '4. Выбираете ту работу, где хотите работать', image: phone }
];

export default function Hero() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeFeature, setActiveFeature] = useState(0);
  const [prevFeature, setPrevFeature] = useState(0);
  const [imageTransition, setImageTransition] = useState(false);

  const handleFeatureChange = (index) => {
    setImageTransition(true);
    setPrevFeature(activeFeature);
    setActiveFeature(index);
    setTimeout(() => setImageTransition(false), 500);
  };

  return (
    <section className={styles.hero}>


      <div className={styles.content}>
        <div className={styles.infoBlock}>
          <ul className={styles.featuresList}>
            {featuresData.map((feature, index) => (
              <li 
                key={index}
                className={index === activeFeature ? styles.active : ''}
                onMouseEnter={() => handleFeatureChange(index)}
                onClick={() => handleFeatureChange(index)}
              >
                {feature.text}
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.imageBlock}>
          {featuresData.map((feature, index) => (
            <img
              key={index}
              src={feature.image}
              alt="Демонстрация работы"
              className={`${styles.featureImage} ${
                index === activeFeature ? styles.imageActive : 
                index === prevFeature ? styles.imageFadeOut : ''
              }`}
            />
          ))}
        </div>
      </div>


    </section>
  );
}