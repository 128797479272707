import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import CoolInfo from './components/CoolInfo/CoolInfo';
import InfoBlock from './components/InfoBlock/InfoBlock';

import styles from './index.css';

function App() {


  return (
    <>
      <Header />
      <main>
      <CoolInfo/>
      <InfoBlock/>
      <Hero/>
      </main>
      <footer>
      </footer>


    </>
  );
}

export default App;